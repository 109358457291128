
import Vue from 'vue';
import { VueConstructor } from 'vue/types';
import { TranslateResult } from 'vue-i18n/types';
import { VForm } from '@/types';
import formHelper from '@/mixins/formHelper';

import USER from '@/store/modules/UserModule';
import UTILS from '@/store/modules/UtilityModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import { ErrorResponse } from '~/types/api_helper';
import { getHiddenPasswordAndRealPassword } from '~/_helpers/misc_helper';
import currentDomain from '@/mixins/currentDomain';

const LoginForm = (Vue as VueConstructor<Vue & InstanceType<typeof formHelper>>).extend({
	name: 'LoginForm',

	mixins: [formHelper, currentDomain],

	data() {
		return {
			email: '',
			password: '',
			password_value: '',
			magic_contact: '',

			reset_email: '',
			reset_link: '',
			reset_pw_form: false,
			reset_success: false,

			magic_valid: false,
			magic_link_form: false,
			magic_success: false,
			magic_error: false,

			tooltip: false,

			valid: false,
			reset_valid: false,

			ml_error: false,
			login_error: false,
			reset_error: false,

			btn_loading: false
		};
	},

	computed: {
		USER: () => USER,
		MISC_DATA: () => MISC_DATA,
		UTILS: () => UTILS,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		formLogin(): VForm {
			return this.$refs.formLogin as VForm;
		},

		formReset(): VForm {
			return this.$refs.formReset as VForm;
		},

		formMagic(): VForm {
			return this.$refs.formMagic as VForm;
		},

		generalErrorMessage(): TranslateResult | boolean {
			if (this.login_error) {
				return this.$t('alert.validation.invalid_login');
			}

			if (this.ml_error) {
				return this.$t('alert.validation.invalid_ml.text');
			}

			return false;
		}
	},

	watch: {
		magic_link_form() {
			if (this.magic_link_form) {
				this.$nextTick(() => {
					this.magic_contact = '';
					this.formMagic.resetValidation();
				});
			}
		},

		reset_pw_form() {
			if (this.reset_pw_form) {
				this.$nextTick(() => {
					this.password = '';
					this.formReset.resetValidation();
				});
			}
		}
	},

	mounted() {
		if (this.$route.query.magic_link === 'invalid') {
			this.ml_error = true;
		}
	},

	methods: {
		async login() {
			if (!this.formLogin.validate()) {
				return;
			}
			this.btn_loading = true;
			this.login_error = false;

			const res = await USER.LOGIN({ email: this.email, password: this.password_value });

			if (res instanceof ErrorResponse) {
				this.login_error = true;
			} else {
				this.$emit('success');
			}

			this.btn_loading = false;
		},

		handlePassword(input_event: Event | any): void {
			if (input_event.target && input_event.target.type === 'password') { // If not a password input, we can directly set the value
				this.password_value = input_event.target.value;
				return
			}
			const new_pws = getHiddenPasswordAndRealPassword({ input_event, pw_to_hide: this.password, real_pw: this.password_value });
			this.password = new_pws.new_pw_to_hide;
			this.password_value = new_pws.new_real_pw;
		},

		async resetPassword() {
			if (!this.formReset.validate()) {
				return;
			}

			this.reset_error = false;

			const link = await USER.RESET_PASSWORD_REQUEST(this.reset_email);
			if (link !== false) {
				this.reset_success = true;
			} else {
				this.reset_error = true;
			}
		},

		async magicRequest() {
			if (!this.formMagic.validate()) {
				return;
			}
			this.btn_loading = true;
			this.magic_error = false;

			const contact = { contact: this.magic_contact };
			const res = await USER.GET_MAGIC_LINK(contact);

			if (res) {
				this.$logger.console({ message: 'Magic Request response', data: res });
				this.magic_success = true;
			} else {
				this.magic_error = true;
			}

			this.btn_loading = false;
		}
	}
});

export type LoginFormRef = InstanceType<typeof LoginForm>;
export default LoginForm;
